import quoteService from '@/services/quoteService';
import helperService from '@/services/helperService';

const Animate = () => import(/* webpackChunkName: "view-Shipping" */ '@/common/animate/Animate.vue');
const calendar = () => import(/* webpackChunkName: "view-Shipping" */ '@/common/calendar/Calendar.vue');

export default {
	name: 'shipping',
	components: {
		'calendar': calendar,
		'animate-scene': Animate,
	},
	data: function() {
		return {
			step: 3,
			currDate: null,
			value: '14/07/2018',
			format: 'dd/MM/yyyy',
			shipping_date: null,
			size: '',
			styleObject: {},
			animate: false,
			completed: false,
			info_msg: "If you don't have a specific date, click continue.",
			has_error: false,
		};
	},
	beforeRouteEnter: function(to, from, next) {
		if (from.name || helperService.isValidLandingState(3)) {
			next(true);
		} else {
			next('/');
		}
	},
	created: function() {
		this.currDate = new Date();
		this.value
			= this.currDate.getMonth()
			+ 1
			+ '/'
			+ this.currDate.getDate()
			+ '/'
			+ this.currDate.getFullYear();
		const date = new Date();

		date.setDate(date.getDate() + 14);
		this.shipping_date = date;

		this.checkForQuoteData();
	},
	mounted: function() {
		helperService.updateProgress(this.step);

		if (quoteService.getMoveLeadToken()) {
			if (window.analytics) {
				window.analytics.page(
					'Shipping',
					{ type: 'page', path: 'Shipping' },
					{ integrations: { AdWords: false } }
				);
			}
		} else {
			document.addEventListener('fetchID', () => {
				if (window.analytics) {
					window.analytics.page(
						'Shipping',
						{ type: 'page', path: 'Shipping' },
						{ integrations: { AdWords: false } }
					);
				}
			});
		}
	},
	beforeDestroy: function() {
		if (this.shipping_date) {
			// let params = helperService.getQueryParams();
			// params.date = this.shipping_date.toString();
			// helperService.sendMixpanelData("Date Selected", params);
		}
	},
	methods: {
		checkForQuoteData: function() {
			const quote = quoteService.getQuote();

			this.size = quote.size_type || 'apartment';

			if (quote && quote.shipping_date) {
				this.shipping_date = new Date(quote.shipping_date);
			}
		},
		onDayClick2: function(date, dateText) {
			if (date < this.currDate) {
				this.has_error = true;
				this.info_msg = 'Please select date greater than today.';

				return;
			}

			this.shipping_date = date;
			quoteService.setShippingDate(date);

			this.animate = true;
			setTimeout(() => {
				this.completed = true;
				this.moveNext();
			}, 600);
		},
		moveNext: function() {
			setTimeout(() => {
				this.$router.push('/delivery');
			}, 1000);
		},
	},
};
